import { createContext, useContext, useState, useEffect, memo } from 'react';
import { useApi } from '../api';

import type { ReactNode } from 'react';
import type { AuthState } from '../api/types';

type Context = AuthState & {
  isChecking: boolean;
  hasExtension: { optimistic: boolean; real: boolean };
  needsMigration?: boolean;
};

const initial: Context = {
  isChecking: true,
  /**
   * To know if the extension is installed a few seconds are required to receive the initial detection
   * event from the extension. That's why there are two options, depending on the desired UI behaviour.
   */
  hasExtension: {
    /**
     * Initially true but once checked can become false if not installed.
     */
    optimistic: true,
    /**
     * Initially false but once checked can become true if installed.
     */
    real: false,
  },
};

export const context = createContext(initial);
export const useAuth = () => useContext(context);

export const AuthProvider = memo<{ children: ReactNode }>(
  function AuthProvider({ children }) {
    const api = useApi();
    const [auth, setAuth] = useState(initial);

    useEffect(() => {
      api.auth.onAuthChanged((authState) => {
        setAuth((auth) => ({ ...auth, ...authState, isChecking: false }));
      });
    }, [api]);

    // On mount check if Polyflow extension is available.
    useEffect(() => {
      // If no responsePolyflowExtension event is received from the extension after 5s it is considered to not be installed.
      const timeoutId = window.setTimeout(() => {
        setAuth((auth) => ({
          ...auth,
          hasExtension: { optimistic: false, real: false },
        }));
      }, 5000);

      const listener = () => {
        setAuth((auth) => ({
          ...auth,
          hasExtension: { optimistic: true, real: true },
        }));
        // If the extension sends the detection event, cancel the execution of the optimistic update.
        window.clearTimeout(timeoutId);
      };

      window.addEventListener('responsePolyflowExtension', listener);

      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent('requestPolyflowExtension', {
            bubbles: false,
          })
        );
      }, 1000);

      return () => {
        window.clearTimeout(timeoutId);
        window.removeEventListener('responsePolyflowExtension', listener);
      };
    }, []);

    return <context.Provider value={auth}>{children}</context.Provider>;
  }
);
