import style from './StepsList.module.css';

/**
 * To separate the items use css in a class of a wrapper element like this:
 *   ```
 *   .wrapper li:not(:last-child) {
 *     padding-bottom: 16px;
 *   }
 *   ```
 */
export const StepsList = ({
  steps,
}: {
  steps: { text: string; done?: boolean; onClick?: () => void }[];
}) => {
  return (
    <ul className={style.stepsList}>
      {steps.map((s, i, arr) => {
        // Unblocked steps can be done or not done yet, they correspond to the ones that are clickable.
        // An exception is if there is only one step, in which case it will be unblocked but not clickable.
        const unblocked = i > 0 ? (arr[i - 1].done ? true : false) : true;
        return (
          <li
            key={i}
            className={
              (s.done ? style.done : unblocked ? '' : style.blocked) +
              (unblocked ? ' ' + style.clickable : '')
            }
            // If there is only one step it will never be clickable because it will always be already active.
            onClick={arr.length > 1 && unblocked ? s.onClick : undefined}
          >
            {s.text}
          </li>
        );
      })}
    </ul>
  );
};
